<template lang="html">


  <div class="usluga">
    <div class="headUsluga">


    <h1>Key Solutions Candidates</h1>
    <p>Are you looking for a job?</p>
    <p>Fill in the fields below, send us your CV and  become a part of our database. We will contact you when a position that matches your qualifications is opened.</p>
  </div>

    <div class="card-body">
       <form>
         <div class="form-group">
           <label for="name">Full Name</label>
           <input
             class="form-control"
             type="text"
             name="name"
             v-model="name"
             id="name"
             >
         </div>
         <div class="form-group">
           <label for="birthDate">Date of Birth</label>
           <input
             class="form-control"
             type="text"
             name="birthDate"
             v-model="birthDate"
             id="birthDate"
             >
         </div>
         <div class="form-group">
           <label for="residency">Place of residence</label>
           <input
             class="form-control"
             type="text"
             name="residency"
             v-model="residency"
             id="residency"
             >
         </div>
         <div class="form-group">
           <label for="email">E-mail address</label>
           <input
             class="form-control"
             type="text"
             name="email"
             v-model="email"
             id="email"
             >
         </div>
         <div class="form-group">
           <label for="degree">Education</label>
           <input
             class="form-control"
             type="text"
             name="degree"
             v-model="degree"
             id="degree"
             >
         </div>
         <div class="form-group">
           <label for="desiredWorkplace">Desired industry</label>
           <input
             class="form-control"
             type="text"
             name="desiredWorkplace"
             v-model="desiredWorkplace"
             id="desiredWorkplace"
             >
         </div>
         <!-- <div class="form-group">
           <label for="cv">Dodajte CV</label>&nbsp;&nbsp;&nbsp;&nbsp;
           <label for="cv">[[Implementacija file browser-a]]</label>
         </div> -->
        <button class="btn btn-primary" @click="changeBody()">Submit</button>
        &nbsp;&nbsp;&nbsp;&nbsp;

      </form>

  </div>

  </div>

</template>

<script>
export default {

  name: 'KSKandidatiEng',
  components: {

  },
  methods:{

  },
  beforeCreate: function() {
        document.body.className = 'kandidati';
  }

}
</script>

<style lang="css" scoped>

.usluga{
position: absolute;
top: auto;
left: 15%;
right: 15%;
background-color: white;
border-style: solid;
border-color: black;
border-width: medium;
border-radius: 15px;
margin-top: 15px;

}

.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;

}

</style>
